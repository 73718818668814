import website from '../assets/website.png'
import email from '../assets/email.png'
import youtube from '../assets/youtube.png'
import medium from '../assets/medium.png'
import github from '../assets/github.png'
import telegram from '../assets/telegram.png'
import twitter from '../assets/twitter.png'
import discord from '../assets/discord.png'
import reddit from '../assets/reddit.png'


const items = [
{
    "title": "Website",
    "subtitle": "Build frictionless Web3 apps! Etherspot is an account abstraction multi-chain infrastructure",
    "image": website,
    "link": "https://bit.ly/3baYYzq"
},
{
    "title": "TransactionKit",
    "subtitle": "TransactionKit Is A World First React Library for FAST & SIMPLE Web3 Development",
    "image": website,
    "link": "https:///bit.ly/3ZAqNon"
},
{
    "title": "Developer Documentation",
    "subtitle": "Where all the fun part is!",
    "image": website,
    "link": "https://bit.ly/48EH3KP"
},
{
    "title": "Skandha ERC4337 Bundler",
    "subtitle": " A modular, developer-friendly Typescript Bundler for Ethereum EIP-4337 Account Abstraction ",
    "image": website,
    "link": "https://bit.ly/46FFUAM" // to update
},
{
    "title": "BUIDLer",
    "subtitle": "React component for plug & play integration",
    "image": website,
    "link": "https://bit.ly/3Uzuuch"
},
{
    "title": "GitHub",
    "subtitle": "Look at OpenSource magic :)",
    "image": github,
    "link": "https://bit.ly/39Jn6sA" //Github Profile link
},
{
    "title": "Twitter",
    "subtitle": "Let's connect on twitter!",
    "image": twitter,
    "link": "https://bit.ly/3OySxVd"// twitter profile link 
},
{
    "title": "Telegram",
    "subtitle": "Another communication platform?! Ok",
    "image": telegram,
    "link": "https://bit.ly/3QTUWvZ" //Telegram Pofile 
},  
{
    "title": "Discord",
    "subtitle": "Let's chat about Etherspot!",
    "image": discord    ,
    "link": "https://discord.etherspot.io" // linkedin
},
{
    "title": "Medium",
    "subtitle": "We often do blogs...",
    "image": medium,
    "link": "https://bit.ly/3xMBsAn" // medium
},
{
    "title": "YouTube",
    "subtitle": "We make videos!",
    "image": youtube,
    "link": "https://bit.ly/3bmWONa"//youtube channel link 
},
{
    "title": "Mail",
    "subtitle": "Old way?! Let's e-mail!",
    "image": email,
    "link": "mailto:info@etherspot.io"
}]

export default items
